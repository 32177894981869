<template>
  <div>
    <el-row>
      <el-button type="primary" @click="handleOpenContentDialog(1)"
        >新增巡查内容</el-button
      >
    </el-row>
    <el-row style="margin-top: 20px">
      <el-form inline>
        <el-form-item class="formItemBoxStyle" label="巡查主题">
          <el-input placeholder="请输入" v-model="inspectTitle" />
        </el-form-item>
        <el-form-item>
          <el-button @click="handleResetContentBtn">重置</el-button>
          <el-button type="primary" @click="handleQueryContentBtn"
            >查询</el-button
          >
        </el-form-item>
      </el-form>
    </el-row>
    <el-row>
      <el-table
        :data="inspectContentList"
        :header-cell-style="{
          'text-align': 'center',
          backgroundColor: '#dfe6ec',
        }"
        :cell-style="{ 'text-align': 'center' }"
      >
        <el-table-column label="序号" type="index" width="80"></el-table-column>
        <el-table-column
          label="巡查项主题"
          prop="inspectTitle"
        ></el-table-column>
        <el-table-column label="所属科目" prop="subjectName"></el-table-column>
        <el-table-column label="创建者" prop="creator"></el-table-column>
        <el-table-column label="创建时间" prop="time"></el-table-column>
        <el-table-column label="状态" prop="enable">
          <template slot-scope="scope">
            <span>{{ scope.row.enable == 1 ? "启用" : "停用" }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <span
              v-if="scope.row.enable !== 1"
              class="leftBtn"
              @click="handleOpenContentDialog(0, scope.row)"
              >编辑</span
            >
            <span
              v-if="scope.row.enable == 1"
              class="leftBtn"
              @click="handleOpenConfirmDialog(scope.row, '停用')"
              style="padding-right: 0; margin-left: 10px; border-right: none"
              >停用</span
            >
            <span
              @click="handleOpenConfirmDialog(scope.row, '启用')"
              v-if="scope.row.enable !== 1"
              class="leftBtn"
              >启用</span
            >
            <span
              @click="handleOpenConfirmDialog(scope.row, '删除')"
              v-if="scope.row.enable !== 1"
              class="delBtn"
              >删除</span
            >
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <!-- 分页组件 -->
    <el-row style="margin-top: 20px">
      <el-pagination
        @size-change="handleSizeChangeContentPageInfo"
        @current-change="handleCurrentChangeContentPageInfo"
        :current-page="contentPageInfo.pageIndex"
        :page-sizes="[10, 20, 50, 100, 150, 200]"
        :page-size="contentPageInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="contentPageInfo.pageTotal"
      ></el-pagination>
    </el-row>
    <el-dialog
      :title="addOrEditInspect == 1 ? '新增巡查内容' : '编辑巡查内容'"
      :visible.sync="inspectDialogVisible"
    >
      <el-form :model="inspectForm" ref="inspectForm" :rules="rules">
        <el-form-item label="巡查主题名称：" prop="inspectTitle">
          <el-input
            style="width: 700px"
            placeholder="请输入"
            v-model="inspectForm.inspectTitle"
          />
        </el-form-item>
        <el-form-item
          style="margin-left: 28px"
          label="所属科目："
          prop="subjectId"
        >
          <el-select
            placeholder="请输入"
            style="width: 400px"
            v-model="inspectForm.inspectTitle"
          ></el-select>
        </el-form-item>
        <el-form-item
          style="margin-left: 28px"
          label="巡查项："
          prop="inspectTitle"
        >
          <div style="text-align: right">
            <el-button>导入</el-button>
            <el-button type="primary" @click="handleAddInspectItem"
              >新增巡查项</el-button
            >
          </div>
        </el-form-item>
      </el-form>
      <el-row>
        <el-table
          border
          :data="inspectItemList"
          :header-cell-style="{
            'text-align': 'center',
            backgroundColor: '#dfe6ec',
          }"
          :cell-style="{ 'text-align': 'center' }"
        >
          <el-table-column
            label="序号"
            type="index"
            width="80"
          ></el-table-column>
          <el-table-column label="巡查项名称" prop="name" width="200">
            <template slot-scope="scope">
              <el-input
                placeholder="请输入"
                v-model="scope.row.name"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column label="巡查内容" prop="content">
            <template slot-scope="scope">
              <el-input
                placeholder="请输入"
                v-model="scope.row.content"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="100">
            <template slot-scope="scope">
              <span
                @click="handleDeleteInspectItemBtnClick(scope.$index)"
                style="
                  color: rgb(217, 0, 27);
                  text-decoration: underline;
                  cursor: pointer;
                "
                >删除</span
              >
            </template>
          </el-table-column>
        </el-table>
      </el-row>
      <!-- 分页组件 -->
      <el-row style="margin-top: 20px">
        <el-pagination
          @size-change="handleSizeChangeItemPageInfo"
          @current-change="handleCurrentChangeItemPageInfo"
          :current-page="itemPageInfo.pageIndex"
          :page-sizes="[5, 10, 20, 50, 100]"
          :page-size="itemPageInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="itemPageInfo.pageTotal"
        ></el-pagination>
      </el-row>
      <div style="text-align: right; margin-top: 20px">
        <el-button
          @click="
            () => {
              inspectDialogVisible = false;
            }
          "
          >取消</el-button
        >
        <el-button type="primary">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      inspectTitle: "",
      inspectContentList: [],
      contentPageInfo: {
        pageIndex: 1,
        pageSize: 10,
        pageTotal: 0,
      },
      inspectDialogVisible: false,
      addOrEditInspect: 1, // 1 新增 0 编辑
      inspectForm: {
        inspectTitle: "",
        subjectId: "",
      },
      rules: {
        inspectTitle: [
          { required: true, message: "请输入巡查主题名称", trigger: "blur" },
        ],
        subjectId: [
          { required: true, message: "请选择所属科目", trigger: "change" },
        ],
      },
      tempInpectItemList: [],
      itemPageInfo: {
        pageIndex: 1,
        pageSize: 5,
        pageTotal: 0,
      },
    };
  },
  computed: {
    inspectItemList() {
      let startIndex =
        (this.itemPageInfo.pageIndex - 1) * this.itemPageInfo.pageSize;
      let endIndex = startIndex + this.itemPageInfo.pageSize;
      return this.tempInpectItemList.slice(startIndex, endIndex);
    },
  },
  methods: {
    // 获取内容数据
    fetchInspectContentDataList() {},
    // 新增或编辑按钮
    handleOpenContentDialog(index, row) {
      this.addOrEditInspect = index;
      if (index == 1) {
        // 新增按钮
        this.inspectDialogVisible = true;
      } else {
        // 编辑按钮
      }
    },
    // 停用、启用、删除按钮
    handleOpenConfirmDialog(row, val) {
      this.$confirm(`确定${val}该巡查内容吗？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (val === "删除") {
            // 这里进行删除操作
          } else {
            let params = {
              rowId: row.id,
              enabled: val === "启用" ? 1 : 2,
            };
            // 这里进行编辑操作
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: `已取消${val}`,
          });
        });
    },
    // 修改主表当前页
    handleCurrentChangeContentPageInfo(val) {
      this.contentPageInfo.pageIndex = val;
      this.fetchInspectContentDataList();
    },
    // 修改主表页容量
    handleSizeChangeContentPageInfo(val) {
      this.contentPageInfo.pageIndex = 1;
      this.contentPageInfo.pageSize = val;
      this.fetchInspectContentDataList();
    },
    // 重置表格
    handleResetContentBtn() {
      this.inspectTitle = "";
      this.handleQueryContentBtn();
    },
    // 查询表格
    handleQueryContentBtn() {
      this.contentPageInfo.pageIndex = 1;
      this.contentPageInfo.pageSize = 10;
      this.fetchInspectContentDataList();
    },
    // 点击新增巡查项
    handleAddInspectItem() {
      if (this.tempInpectItemList.length > 0) {
        if (
          !this.tempInpectItemList[this.tempInpectItemList.length - 1].name ||
          !this.tempInpectItemList[this.tempInpectItemList.length - 1].content
        ) {
          this.$message.warning("请完成巡查项填写");
          return;
        }
      }
      this.tempInpectItemList.push({
        name: "",
        content: "",
      });
      this.itemPageInfo.pageTotal++;
    },
    // 点击删除巡查项
    handleDeleteInspectItemBtnClick(index) {
      // this.inspectItemList.splice(index, 1);
      let delIndex =
        (this.itemPageInfo.pageIndex - 1) * this.itemPageInfo.pageSize + index;
      this.tempInpectItemList.splice(delIndex, 1);
      this.itemPageInfo.pageTotal--;
      if (this.inspectItemList.length == 0) {
        this.handleSizeChangeItemPageInfo(this.itemPageInfo.pageSize);
      }
    },
    // 修改页容量
    handleSizeChangeItemPageInfo(val) {
      this.itemPageInfo.pageIndex = 1;
      this.itemPageInfo.pageSize = val;
    },
    // 修改当前页
    handleCurrentChangeItemPageInfo(val) {
      this.itemPageInfo.pageIndex = val;
    },
  },
};
</script>

<style lang="scss" scoped>
.leftBtn {
  margin-right: 10px;
  padding-right: 10px;
  border-right: 1px solid #00f;
  cursor: pointer;
  color: #00f;
  text-decoration: underline;
}
.delBtn {
  color: rgb(217, 0, 27);
  text-decoration: underline;
  cursor: pointer;
}
</style>
